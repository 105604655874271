
import { defineComponent, ref, onMounted, computed } from "vue";
import Datatable from "@/components/shared/Datatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/config";
import useTranslate from "@/core/composable/useTranslate";
import { useStore } from "vuex";
import { setParamsPlatform } from "@/core/helpers/platform";
import ProgerssBar from "@/components/shared/progerssBar.vue";

interface Pagination {
  page: number;
  rowsPerPage: number;
}

export default defineComponent({
  name: "platforms-listing",
  components: {
    Datatable,
    ProgerssBar,
  },
  setup() {
    const { translate } = useTranslate();
    const store = useStore();
    const tableHeader = ref([
      {
        key: "checkbox",
      },
      {
        name: `${translate("platform")} `,
        key: "platform",
        sortable: true,
      },
      {
        name: `${translate("advancement")} `,
        key: "advancement",
        sortable: true,
      },
      {
        name: `${translate("dueDate")} `,
        key: "duedate",
        sortable: true,
      },
      {
        name: `${translate("members")} `,
        key: "members",
      },
      {
        name: `${translate("status")} `,
        key: "status",
        sortable: true,
      },
      {
        name: ``,
        key: "actions",
      },
    ]);

    const allPlatformsList = computed(() => {
      return store.getters.allPlatforms.data;
    });

    const allPlatformsCount = computed(() => {
      return store.getters.allPlatforms?.options?.totalCount;
    });

    const pagination = ref<Pagination>({
      page: 1,
      rowsPerPage: parseInt(localStorage.getItem("limit") || "5"),
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Platform Listing", [
        "Platform",
        "All Platforms",
      ]);
      setParamsPlatform(pagination.value);
    });

    const search = ref<string>("");

    return {
      tableHeader,
      search,
      allPlatformsList,
      allPlatformsCount,
      setParamsPlatform,
    };
  },
});
