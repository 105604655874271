import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const setPlatformInfo = (data: any) => {
  return store.dispatch(Actions.FETCH_CREATE_PLATFORM, {
    data: data,
  });
};

export const setPlatformOverview = async (id: number) => {
  await store.dispatch(Actions.FETCH_PLATFORM_OVERVIEW, {
    id: id,
  });
};

export const setParamsPlatform = async (data: any) => {
  await store.dispatch(Actions.FETCH_ALL_PLATFORMS, {
    data: data,
  });
};
