import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_6 = {
  key: 0,
  class: "card-body pt-0"
}
const _hoisted_7 = ["data-bs-target"]
const _hoisted_8 = { class: "btn btn-sm btn-icon mw-20px btn-active-color-primary me-5" }
const _hoisted_9 = { class: "svg-icon toggle-on svg-icon-primary svg-icon-1" }
const _hoisted_10 = { class: "svg-icon toggle-off svg-icon-1" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  key: 0,
  class: "d-flex mw-50"
}
const _hoisted_14 = { class: "symbol-group symbol-hover" }
const _hoisted_15 = { class: "symbol symbol-35px symbol-circle" }
const _hoisted_16 = { class: "bg-light-success text-success symbol-label fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_ProgerssBar = _resolveComponent("ProgerssBar")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "svg/icons/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            class: "form-control form-control-solid w-250px ps-15",
            placeholder: "Search platform"
          }, null, 512), [
            [_vModelText, _ctx.search]
          ])
        ])
      ])
    ]),
    (_ctx.allPlatformsList && _ctx.allPlatformsCount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_Datatable, {
            "table-data": _ctx.allPlatformsList,
            "table-header": _ctx.tableHeader,
            total: _ctx.allPlatformsCount,
            "enable-items-per-page-dropdown": true,
            onItemsPerPageChange: _ctx.setParamsPlatform
          }, {
            "cell-checkbox": _withCtx(({ row: platform }) => [
              _createElementVNode("div", {
                class: "d-flex align-items-center collapsible py-3 toggle collapsed mb-0",
                "data-bs-toggle": "collapse",
                "data-bs-target": `#kt_job_${platform.id}`
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, [
                    _createVNode(_component_inline_svg, { src: "svg/icons/gen036.svg" })
                  ]),
                  _createElementVNode("span", _hoisted_10, [
                    _createVNode(_component_inline_svg, { src: "svg/icons/gen035.svg" })
                  ])
                ])
              ], 8, _hoisted_7)
            ]),
            "cell-platform": _withCtx(({ row: platform }) => [
              (platform.name)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(platform.name), 1))
                : _createCommentVNode("", true)
            ]),
            "cell-advancement": _withCtx(({ row: platform }) => [
              _createVNode(_component_ProgerssBar, {
                percentage: platform.advancement,
                label: `${Math.floor(platform.advancement)}%`,
                "text-inside": false
              }, null, 8, ["percentage", "label"])
            ]),
            "cell-duedate": _withCtx(({ row: platform }) => [
              (platform.dueDate)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(platform.dueDate.substring(0, 10)), 1))
                : _createCommentVNode("", true)
            ]),
            "cell-members": _withCtx(({ row: platform }) => [
              (platform.memberFirstName && platform.memberLastName)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("span", _hoisted_16, _toDisplayString(platform.memberFirstName.charAt(0).toUpperCase()) + _toDisplayString(platform.memberLastName.charAt(0).toUpperCase()), 1)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            "cell-status": _withCtx(({ row: platform }) => [
              (platform.status)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(["badge", [
              platform.advancement === null && 'badge-light-1',
              platform.advancement &&
                platform.advancement < 100 &&
                'badge-light-4',
              platform.advancement === 100 && 'badge-light-5',
            ]])
                  }, _toDisplayString(platform.status), 3))
                : _createCommentVNode("", true)
            ]),
            "cell-actions": _withCtx(({ row: platform }) => [
              (platform.id)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: `/platform/${platform.id}/overview`,
                    class: "btn btn-sm btn-light btn-active-light-primary",
                    "data-kt-menu-trigger": "click",
                    "data-kt-menu-placement": "bottom-end",
                    "data-kt-menu-flip": "top-end"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("view")), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["table-data", "table-header", "total", "onItemsPerPageChange"])
        ]))
      : _createCommentVNode("", true)
  ]))
}